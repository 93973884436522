import React, {lazy, Suspense, useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios"
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import AdminSection from "./AdminSection/AdminSection";
const AdminSection= lazy(() => import("./AdminSection/AdminSection"));
const Client = lazy(() => import("./Client/Client"));
const QuizMasterAdmin = lazy(() => import("./QuizMasterAdmin/QuizMasterAdmin"));
const ClientAdmin = lazy(() => import("ClientAdmin/ClientAdmin"));
// const toast = lazy(()=>import ("react-toastify"))

// import Client from "./Client/Client";
// import QuizMasterAdmin from "./QuizMasterAdmin/QuizMasterAdmin";
// import ClientAdmin from "ClientAdmin/ClientAdmin";

function App() {
  let theme = createTheme({});
  theme = createTheme(theme, {
    palette: {
      bgColor: theme.palette.augmentColor({
        color: {
          main: "#F9F9F9",
        },
      }),
      navBg: {
        main: "#005F6A",
      },
      orange: {
        main: "#FFF0C9",
        dark: "#F8B813",
      },
      blue: {
        dark: "#005F6A",
        main: "#005F6A",
        light: "#F0FDFF",
      },
      grey: {
        dark: "#7E7E7E",
        main: "#7E7E7E",
        light: "#E9E9E9",
      },
      green: {
        light: "#E3FFE0",
        main: "#28B536",
        dark: "#28B536",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path="/admin/*" element={<Suspense fallback={<>Loading...</> }><AdminSection /></Suspense>} />
            <Route exact path="/*" element={
              <Suspense fallback={<>Loading...</> }> <Client /></Suspense>
             } />
            <Route exact path="/waitlist" element={
              <Suspense fallback={<>Loading...</> }> <Waitlist /></Suspense>
             } />
            <Route exact path="/quizmaster/*" element={
             <Suspense fallback={<>Loading...</> }>  <QuizMasterAdmin /></Suspense>} />
            <Route exact path="/clientadmin/*" element={ <Suspense fallback={<>Loading...</> }><ClientAdmin /></Suspense>} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;


function Waitlist(){
  const [waitlistData, setWaitListData]= useState([])
  async function getWaitlistData(){
    axios.get(`https://cocreate.9ijakids.com/api/wishlists/users-by-platform?per_page=1000000 `).then(data=>{
console.log({test:data.data.data})
      setWaitListData(data?.data?.data)
    })

  }

useEffect(()=>{
  getWaitlistData()
},[])
 console.log({waitlistData})
 const columns = [
  { field: 'name', headerName: 'Name', width: 250 },
  { field: 'phoneNumber', headerName: 'Phone', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'platform', headerName: 'Platform', width: 250 },
  // { field: 'total_point', headerName: 'Overall Score', width: 150 },
  // // { field: "rank", headerName: "Rank", width: 150 },
  // { field: 'attempts', headerName: 'Attempt', width: 150 },
  // { field: 'updatedAt', headerName: 'Last seen', width: 150 },
  // { field: "", headerName: "", width: 150 },
];
  return  <div className="p-4" style={{ height: 500 }}>
<DataGrid
rows={waitlistData}
slots={{ toolbar: GridToolbar }} 
columns={columns}
sx={{
  '& .MuiDataGrid-columnHeader': {
    background: '#F5F5F5',
  },
  '& .MuiDataGrid-row': {
    background: 'white',
  },
}}

/>
    </div>
}